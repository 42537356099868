class EventBus {
	static instance = new EventBus();

	constructor() {
		if (!!EventBus.instance) {
			return EventBus.instance;
		}

		this.bus = document;
		EventBus.instance = this;

		return this;
	}

	addEventListener(event, callback) {
		this.bus.addEventListener(event, callback);
	}

	removeEventListener(event, callback) {
		this.bus.removeEventListener(event, callback);
	}

	dispatchEvent(event, detail = {}) {
		//console.debug('EventBus.dispatchEvent', event, detail);
		this.bus.dispatchEvent(new CustomEvent(event, {detail}));
	}

	static getInstance() {
		return this.instance;
	}
}

window.EventBusInstance = EventBus.getInstance();